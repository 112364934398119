import request from '@http';

// 2022/06/06 王江毅 调拨单列表
export function getAssetsAllocateList(params) {
    return request({
        method: 'GET',
        url: '/api/assets/allocate/list',
        params
    })
}

// 2022/06/06 王江毅 调拨申请单
export function assetsAllocatePublish(data) {
    return request({
        method: 'PUT',
        url: '/api/assets/allocate/publish',
        data
    })
}

// 2022/06/06 王江毅 审批调拨单
export function assetsAllocateStatus(uuid,state) {
    return request({
        method: 'get',
        url: `/api/assets/allocate/status/${uuid}/${state}`
    })
}

// 2022/06/08 王江毅 获取有资产房间列表
export function allocateRoomlist() {
    return request({
        method: 'get',
        url: '/api/assets/roomlist',
    })
}

// 2022/06/08 王江毅 获取有资产房间列表
export function allocateRoomAllList() {
    return request({
        method: 'get',
        url: '/api/assets/roomAllList',
    })
}
// 2022/06/08 王江毅 房间内无分页资产列表
export function allocateNolist(params) {
    return request({
        method: 'get',
        url: '/api/assets/allocate/nolist',
        params
    })
}