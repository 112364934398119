<template>
    <section class="assetTransfer">
        <div class="top-bar bg-white" style="justify-content: left">
            <div style="margin-right: 10px;">
                <span style="padding: 0 10px">资产名称:</span>
                <el-input size="small" style="width: 200px;" v-model="formSearch.assetsName" placeholder="请输入资产名称"/>
            </div>
            <div style="margin-right: 10px;">
                <span style="padding: 0 10px">资产状态:</span>
                <el-select size="small" v-model="formSearch.assetsState" style="width: 200px;" placeholder="请选择资产状态">
                    <el-option label="全部" :value="0"/>
                    <el-option v-for="(item,index) in assetsStateList" :key="index" :label="item.codeName" :value="item.code"/>
                </el-select>
            </div>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset">重置</el-button>
            <!--<el-button type="primary" size="small" @click="handleContractExport">导出数据</el-button>-->
            <el-button type="primary" size="small" icon="el-icon-plus" @click="openAdd">新建</el-button>
            <el-dropdown style="margin-left: 10px">
                <el-button type="primary" size="small">
                    导出<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>PDF</el-dropdown-item>
                    <el-dropdown-item>CSV</el-dropdown-item>
                    <el-dropdown-item>Excel</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div style="padding: 25px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="rentTableRef" :dataRequest="getAssetsAllocateList" :columns="assetTransferColumn"
                       :query="formSearch" :height="800">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="150">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" v-if="row.stateCode===0" @click="updataState(row,1)">完成</span>
                            <span class="table-btn" v-if="row.stateCode===0" @click="updataState(row,2)">取消</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <dialog-add-asset-transfer ref="dialogAddAssetTransfer" @handleSearch="handleSearch" />
    </section>
</template>

<script>
    import {assetTransferColumn} from "@/views/property-management/assets-management/data";
    import {getAssetsAllocateList,assetsAllocateStatus} from "@/api/assetTransfer";
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    export default {
        name: "assetTransfer",
        components: {
            dialogAddAssetTransfer:()=>import("./components/dialog-add-assetTransfer"),
        },
        data() {
            return {
                assetTransferColumn,
                formSearch: {
                    assetsName: "",
                    assetsState: 0,
                },
                assetsStateList: [],
            };
        },
        methods: {
            getAssetsAllocateList(params){
                let formSearch = this.formSearch;
                let paramsData = {...params, ...formSearch}
                return getAssetsAllocateList(paramsData);
            },
            handleSearch(){
                this.$refs["rentTableRef"].pNumber = 1;
                this.$refs["rentTableRef"].getTableData();
            },
            handleReset(){
                this.formSearch.assetsName = "";
                this.formSearch.assetsState = 0;
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            updataState({uuid},state){
                this.$confirm(`此操作将${state===1?'完成':'取消'}该审批,是否继续?`, '注意', { type: 'warning'
                }).then(async () => {
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    assetsAllocateStatus(uuid,state).then(res => {
                        MessageSuccess("审批成功");
                        loading.close();
                        this.handleSearch();
                    }).catch(err => {
                        MessageError("审批失败");
                        loading.close();
                    });
                }).catch(() => {});
            },
            openAdd(){
                this.$refs["dialogAddAssetTransfer"].openDialog();
            }
        },
        async mounted() {
            this.assetsStateList = await this.$store.dispatch('app/getDictionaryByCodeActions', 700100);
        },
        watch: {}
    }
</script>
<style lang="scss" scoped>
    .assetTransfer {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>